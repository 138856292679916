import { Card, FaIcon, FaIcons, Toast } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { OneWayInServiceProviderDto } from 'src/api/v2';

type Props = {
  actorMessage: string;
  customerMessage: string;
  customerContactEmailAddress: string;
  selectedRows: OneWayInServiceProviderDto[];
};

const SendReferral: React.FC<Props> = ({
  actorMessage,
  customerMessage,
  customerContactEmailAddress,
  selectedRows,
}) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [searchParams] = useSearchParams();

  return (
    <div className="display--flex flex-direction--column gap--6">
      <p className="margin-bottom--0 margin-top--0">{tOneWayIn('referral.sendReferral.sendReferralInfo')}</p>
      <Card boxShadow="elevated" className="message-display--card">
        <h4 className="margin-top--0 margin-bottom--2">Ny henvisning i Flerbedriftsportalen</h4>
        <span>
          {tOneWayIn('to')}
          {selectedRows
            .filter((row) => row.name !== null && row.name !== '')
            .map((row) => row.name)
            .sort()
            .join(', ')}
        </span>
        <p className="margin-top--3 font-size--medium">{actorMessage}</p>
      </Card>
      {customerMessage.length > 0 && (
        <Card boxShadow="elevated" className="message-display--card">
          <h4 className="margin-top--0 margin-bottom--2">Informasjon om henvisning</h4>
          <span>
            {tOneWayIn('to')}
            {customerContactEmailAddress}
          </span>
          <p className="margin-top--3 font-size--medium">{customerMessage}</p>
        </Card>
      )}
      <Toast
        className="toast--error"
        visible={searchParams.toString() === 'referralStatus=failure'}
        icon={<FaIcon className="color--red" name={FaIcons.InfoCircle} />}
        header={tOneWayIn('referral.sendReferral.referralError.header')}
        position="top-right"
        text={tOneWayIn('referral.error.text')}
      />
    </div>
  );
};

export default SendReferral;
