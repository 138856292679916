import React, { ReactNode } from 'react';
import '../styles/BillsForm.scss';
import { Button, FaIcons, Table } from '@in/component-library';
import { useFieldArray, useForm } from 'react-hook-form';
import Input from 'src/components/Form/Input';
import { requiredI18n } from 'src/utils/validation';
import { useTranslation } from 'react-i18next';
import { Select } from 'src/components/Form/Select';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import { calculateInnovationSupport, calculateSupportIntensity } from '../utils/calculations';
import BillSupportNumberHandler from './BillSupportNumberHandler';
import { SivaEsaReportingDto, SivaPhase } from 'src/api/v2';
import useCompanyContract from '../hooks/use-company-contract';

type Props = {
  header: string;
  bills?: SivaEsaReportingDto[];
  numericId?: number;
  onSubmit: (bills: SivaEsaReportingDto[]) => void;
  onCancel: () => void;
  isLoading?: boolean;
  hideRemoveOption?: boolean;
};

type BillsFormData = {
  bills: SivaEsaReportingDto[];
};

const BillsForm: React.FC<Props> = ({
  header,
  bills,
  numericId,
  onSubmit,
  onCancel,
  isLoading = false,
  hideRemoveOption = false,
}) => {
  const { t: tCommon } = useTranslation();

  const { t: tError } = useTranslation('error');
  const { t: tTableTitles } = useTranslation('tableTitles');
  const { t: tSiva } = useTranslation('siva');
  const { contract, currentPhase, company } = useCompanyContract(numericId);

  const emptyBill: SivaEsaReportingDto = {
    sivaContractNumericalId: numericId ?? 0,
    date: new Date(),
    id: undefined,
    invoiceNumber: '',
    sivaPhase: company?.contracts[0].phases[0].sivaPhase || SivaPhase.PreIncubation,
    marketValue: 0,
    invoicedDeductible: 0,
    sivaContractName: undefined,
  };

  const phaseOptions = React.useMemo(() => {
    if (company) {
      const phases = [
        ...new Set(
          company.contracts.flatMap((contract) =>
            contract.phases
              .filter((phase) =>
                phase.toDate
                  ? new Date(phase.toDate).getFullYear() >= new Date().getFullYear()
                  : phase.toDate === null,
              )
              .filter((phase) => phase.sivaPhase !== SivaPhase.Idea)
              .filter((phase) => phase.sivaPhase !== SivaPhase.Alumni)
              .map((phase) => phase.sivaPhase),
          ),
        ),
      ];
      return phases.map((phase) => ({
        value: phase,
        text: tSiva(`phase.${phase}` as any, { defaultValue: phase }),
      }));
    } else if (contract && currentPhase?.sivaPhase === SivaPhase.PreIncubation) {
      // This is an edge-case to handle contracts in pre-incubation without a company (yet).
      // We were told we would not have to handle this, but ... lies!
      const phases = [
        ...new Set(
          contract.phases
            .filter((phase) =>
              phase.toDate
                ? new Date(phase.toDate).getFullYear() >= new Date().getFullYear()
                : phase.toDate === null,
            )
            .filter((phase) => phase.sivaPhase !== SivaPhase.Idea)
            .filter((phase) => phase.sivaPhase !== SivaPhase.Alumni)
            .map((phase) => phase.sivaPhase),
        ),
      ];
      return phases.map((phase) => ({
        value: phase,
        text: tSiva(`phase.${phase}` as any, { defaultValue: phase }),
      }));
    }
    return [];
  }, [company, tSiva]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<BillsFormData>({
    mode: 'onBlur',
    defaultValues: {
      bills: bills || [
        {
          ...emptyBill,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bills',
  });
  const watchFields = watch('bills');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFields[index],
    };
  });

  const addEmptyBill = () => {
    append({
      ...emptyBill,
    });
  };

  const convertBillToTableRow = (index: number, bill: SivaEsaReportingDto) => {
    const removeButton = (
      <TertiaryButton
        icon={{ prefix: 'fal', iconName: 'times' }}
        aria-label={tCommon('remove')}
        standardizedSize="x-large"
        onClick={() => remove(index)}
        minimalPadding={true}
      />
    );

    const billNr = (
      <Input
        {...register(`bills.${index}.invoiceNumber`)}
        error={errors.bills?.[index]?.invoiceNumber?.message}
        className={'margin--0'}
      />
    );

    const phase = (
      <Select
        options={phaseOptions}
        {...register(`bills.${index}.sivaPhase`, {
          required: requiredI18n(tError),
        })}
        error={errors.bills?.[index]?.sivaPhase?.message}
        className={'margin--0'}
      />
    );

    const marketValue = (
      <Input
        type="number"
        {...register(`bills.${index}.marketValue`, {
          required: requiredI18n(tError),
          valueAsNumber: true,
        })}
        error={errors.bills?.[index]?.marketValue?.message}
        className={'margin--0'}
      />
    );

    const deductible = (
      <Input
        type="number"
        {...register(`bills.${index}.invoicedDeductible`, {
          required: requiredI18n(tError),
          valueAsNumber: true,
        })}
        error={errors.bills?.[index]?.invoicedDeductible?.message}
        className={'margin--0'}
      />
    );

    const innovationSupportCalculation = calculateInnovationSupport(
      bill.marketValue,
      bill.invoicedDeductible,
    );

    const innovationSupport = (
      <div className="text-align--right">
        <BillSupportNumberHandler
          value={innovationSupportCalculation}
          hide={!bill.marketValue && !bill.invoicedDeductible}
        />
      </div>
    );

    const supportIntensityCalculation = calculateSupportIntensity(bill.marketValue, bill.invoicedDeductible);

    const supportIntensity = (
      <div>
        <BillSupportNumberHandler
          formattedValue={`${supportIntensityCalculation} %`}
          hide={!bill.marketValue && !bill.invoicedDeductible}
          value={supportIntensityCalculation}
        />
      </div>
    );

    let cells = [
      removeButton,
      billNr,
      phase,
      marketValue,
      deductible,
      innovationSupport,
      supportIntensity,
    ] as ReactNode[];

    if (hideRemoveOption) {
      cells = cells.slice(1);
    }

    return cells;
  };

  const onSubmitHandler = handleSubmit((formData: BillsFormData) => {
    onSubmit(formData.bills);
  });

  const tableHeaders = [
    <span key="empty" aria-label={tCommon('remove')}></span>,
    tTableTitles('billNumber'),
    tCommon('phase'),
    tTableTitles('marketValue'),
    tTableTitles('deductible'),
    tTableTitles('innovation-support'),
    tTableTitles('support-intensity'),
  ];

  return (
    <form className="siva-esa-bills-form__form" onSubmit={onSubmitHandler}>
      <div className="siva-esa-bills-form__header">
        <h3>{header}</h3>
      </div>
      <div className="margin-left--1 margin-right--1 margin-top--6 margin-bottom--6">
        <Table
          thead={hideRemoveOption ? tableHeaders.slice(1) : tableHeaders}
          rows={controlledFields.map((field, index) => ({
            cells: convertBillToTableRow(index, field),
          }))}
          caption=""
          id="siva-esa-bills-form-table"
        />
        <TertiaryButton
          className="margin-top--1"
          icon={{ prefix: 'fal', iconName: 'plus' }}
          onClick={() => addEmptyBill()}
        >
          {tCommon('addNewRow')}
        </TertiaryButton>
      </div>
      <div className="margin-top--6 display--flex gap--1 margin-left--3">
        <Button type="submit" isLoading={isLoading}>
          {tCommon('save')}
        </Button>
        <Button type="button" theme="neutral" variant="outlined" isLoading={isLoading} onClick={onCancel}>
          {tCommon('cancel')}
        </Button>
      </div>
    </form>
  );
};

export default BillsForm;
