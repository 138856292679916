import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper } from '@in/component-library';
import { useParams, useSearchParams } from 'react-router-dom';
import { OneWayInClosedReason, OneWayInServiceProviderDto, OneWayInStatus } from 'src/api/v2';
import '../styles/Referring.scss';
import { PageParams } from '../types/PageParams';
import SendReferral from '../components/referral/SendReferral';
import ReferralSent from '../components/referral/ReferralSent';
import WriteReferral from '../components/referral/WriteReferral';
import ChooseActor from '../components/referral/ChooseActor';
import NavigationBar from '../components/referral/NavigationBar';
import useOneWayIn from '../../hooks/use-one-way-in';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import createOwiUpdateDto from '../utils/createOwiUpdateDto';
import { useAuth, useUser } from 'src/features/authorization';
import OldReferring from './OldReferring';
import siteimproveEvent from 'src/utils/siteimprove';
import { SITEIMPROVE_DECLARATIONS } from 'src/features/tracking/constants';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';

const Referring: React.FC = () => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  const { formId } = useParams<PageParams>();
  const [searchQuery, setSearchQuery] = useState('');
  const [step, setStep] = useState(0);
  const [selectedRows, setSelectedRows] = useState<OneWayInServiceProviderDto[]>([]);
  const [archiveReferral, setArchiveReferral] = useState(false);
  const [customerEmailSelection, setCustomerEmailSelection] = useState(false);
  const [actorMessage, setActorMessage] = useState('');
  const [customerMessage, setCustomerMessage] = useState('');
  const [referralSent, setReferralSent] = useState(false);
  const [sendReferralInitiated, setSendReferralInitiated] = useState(false);
  const stepCount = 3;

  const { checkAccess } = useAuth();
  const newReferralProcessEnabled = checkAccess('FF_NewReferralProcess').read;

  const [, setSearchParams] = useSearchParams();
  const { oneWayInOpportunityFormQuery, shareOneWayInOpportunity, updateOneWayInOpportunityStatus } =
    useOneWayIn(formId);
  const { isSuccess, isLoading } = shareOneWayInOpportunity;
  const { isAadUser, user, displayName } = useUser();

  const form = oneWayInOpportunityFormQuery.data?.form;

  const customerContactEmailAddress = useMemo(() => {
    if (!form?.kontakt?.epost) return '';
    return form?.kontakt.epost;
  }, [form]);

  const opportunity = useMemo(() => {
    if (!oneWayInOpportunityFormQuery.data) return undefined;

    return oneWayInOpportunityFormQuery.data.opportunity;
  }, [oneWayInOpportunityFormQuery.data]);

  const handleArchiveReferral = () => {
    siteimproveEvent(
      SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
      SITEIMPROVE_DECLARATIONS.ACTIONS.ARCHIVAL_SEND_ATTEMPT,
      formId,
    );
    updateOneWayInOpportunityStatus
      .mutateAsync(
        createOwiUpdateDto(
          opportunity?.id,
          OneWayInStatus.Closed,
          actorMessage,
          isAadUser ? undefined : user?.clusterUserId,
          displayName || undefined,
          OneWayInClosedReason.Other,
        ),
      )
      .then(() => {
        siteimproveEvent(
          SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
          SITEIMPROVE_DECLARATIONS.ACTIONS.ARCHIVAL_SEND_SUCCESS,
          formId,
        );
      })
      .catch(() => {
        siteimproveEvent(
          SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
          SITEIMPROVE_DECLARATIONS.ACTIONS.ARCHIVAL_SEND_FAILURE,
          formId,
        );
      });
  };

  const handleSendReferral = () => {
    siteimproveEvent(
      SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
      SITEIMPROVE_DECLARATIONS.ACTIONS.REFERRAL_SEND_ATTEMPT,
      formId,
    );
    setSendReferralInitiated(true);
    setSearchParams('referralStatus=initiated', { replace: true });
    shareOneWayInOpportunity
      .mutateAsync({
        createDto: {
          receivingClusterIds: selectedRows.map((row) => row.id),
          description: actorMessage,
          sendToActor: true,
          sendToCustomer: customerEmailSelection,
          customerReceivingEmailAddresses: [customerContactEmailAddress],
          actorEmail: { message: actorMessage },
          customerEmail: { message: customerMessage },
          customerName: form?.kontakt?.navn || '',
          handlersName: displayName,
        },
        opportunityId: oneWayInOpportunityFormQuery.data?.opportunity?.id || '',
      })
      .then(() => {
        setSendReferralInitiated(false);
        setReferralSent(true);
        setSearchParams('referralStatus=success', { replace: true });
        siteimproveEvent(
          SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
          SITEIMPROVE_DECLARATIONS.ACTIONS.REFERRAL_SEND_SUCCESS,
          formId,
        );
        if (archiveReferral) handleArchiveReferral();
      })
      .catch(() => {
        setSendReferralInitiated(false);
        setSearchParams('referralStatus=failure', { replace: true });
        siteimproveEvent(
          SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
          SITEIMPROVE_DECLARATIONS.ACTIONS.REFERRAL_SEND_FAILURE,
          formId,
        );
      });
  };

  const getStepText = () => {
    switch (step) {
      case 0:
        return tOneWayIn('referral.steps.step1.text');
      case 1:
        return tOneWayIn('referral.steps.step2.text');
      case 2:
        return tOneWayIn('referral.steps.step3.text');
    }
  };

  return (
    <>
      {newReferralProcessEnabled ? (
        <div>
          <div className="display--flex flex-direction--row gap--3 align-items--center">
            <h1>{tOneWayIn('referral.title')}</h1>
            <div className="referral-info-button--container">
              <TertiaryButton
                className="margin-bottom--1"
                icon={{ prefix: 'fal', iconName: 'sidebar-flip' }}
                iconRight={true}
                standardizedSize={'x-large'}
                minimalPadding={true}
                onClick={() => {
                  window.dispatchEvent(new Event('openMenuAtReferralProcess'));
                }}
              >
                {tHelpAndSupport('referral.ReferralProcess.Explanation')}
              </TertiaryButton>
            </div>
          </div>
          {referralSent ? (
            <>
              {(isLoading || updateOneWayInOpportunityStatus.isLoading) && <LoadingPage />}
              {isSuccess && !updateOneWayInOpportunityStatus.isLoading && (
                <ReferralSent
                  archiveReferral={archiveReferral}
                  archivalError={updateOneWayInOpportunityStatus.isError}
                />
              )}
            </>
          ) : sendReferralInitiated ? (
            <LoadingPage />
          ) : (
            <>
              <div className="width--fit-content">
                <Stepper currentStep={step} stepCount={stepCount} />
                <h3>{getStepText()}</h3>
              </div>
              {step === 0 && (
                <ChooseActor
                  formId={formId}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  setSelectedRows={setSelectedRows}
                />
              )}
              {step === 1 && (
                <WriteReferral
                  actorMessage={actorMessage}
                  customerEmailSelection={customerEmailSelection}
                  customerMessage={customerMessage}
                  selectedRows={selectedRows}
                  setActorMessage={setActorMessage}
                  setCustomerEmailSelection={setCustomerEmailSelection}
                  setCustomerMessage={setCustomerMessage}
                />
              )}
              {step === 2 && (
                <SendReferral
                  actorMessage={actorMessage}
                  customerMessage={customerMessage}
                  customerContactEmailAddress={customerContactEmailAddress}
                  selectedRows={selectedRows}
                />
              )}
              <NavigationBar
                stepCount={stepCount}
                archiveReferral={archiveReferral}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setArchiveReferral={setArchiveReferral}
                handleSendReferral={handleSendReferral}
                setStep={setStep}
                step={step}
                actorMessageEmpty={actorMessage === ''}
                customerMessageEmpty={customerMessage === ''}
                customerEmailSelection={customerEmailSelection}
              />
            </>
          )}
        </div>
      ) : (
        <OldReferring />
      )}
    </>
  );
};

export default Referring;
