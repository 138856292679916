import React, { ReactNode } from 'react';
import './TertiaryButton.scss';
import { FaIcon } from '@in/component-library';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { icon } from '@fortawesome/fontawesome-svg-core';

// Sizes available in utilities.scss
type InSize =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | '2rem'
  | '20px'
  | '40px'
  | '3rem';

function TertiaryButton(props: {
  children?: ReactNode;
  icon?: { prefix: IconDefinition['prefix']; iconName: string };
  onClick?: () => void;
  standardizedSize?: InSize | false;
  minimalPadding?: boolean;
  className?: string;
  ariaLabel?: string;
  iconRight?: boolean;
}) {
  const fontSize = props.standardizedSize ?? 'medium';
  return (
    <button
      className={classNames(
        'tertiary-button',
        fontSize && `font-size--${fontSize}`,
        props.minimalPadding && 'minimal-padding',
        props.className,
      )}
      aria-label={props.ariaLabel}
      type="button"
      onClick={props.onClick}
    >
      <span>
        {props.icon && !props.iconRight && <FaIcon icon={icon(props.icon as IconDefinition)} />}
        {props.children}
        {props.icon && props.iconRight && <FaIcon icon={icon(props.icon as IconDefinition)} />}
      </span>
    </button>
  );
}

export default TertiaryButton;
